/*
 * 最終ダウンロード日時の管理
 */

import { createSlice } from '@reduxjs/toolkit';
import { saveToLocalStorage, loadFromLocalStorage } from '../functions/storageIO';

export const downloadDateSlice = createSlice({
  name: 'lastDownloadDate',
  initialState: loadFromLocalStorage('lastDownloadDate', {}),
  reducers: {
    /* eslint-disable prefer-destructuring, no-param-reassign */
    setLastDownloadDate: (state, action) => {
      state[action.payload[0]] = action.payload[1];
      saveToLocalStorage('lastDownloadDate', state);
    },
    /* eslint-enable prefer-destructuring, no-param-reassign */
  },
});

export const { setLastDownloadDate } = downloadDateSlice.actions;

export default downloadDateSlice.reducer;
