import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';

const Home = React.lazy(() => import('./Home'));
const SignIn = React.lazy(() => import('./SignIn'));
const SignUp = React.lazy(() => import('./SignUp'));
const SignOut = React.lazy(() => import('./SignOut'));

function App() {
  return (
    <Suspense>
      <AuthProvider>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signout" element={<SignOut />} />
        </Routes>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
