/*
 * Firebaseの設定＆初期化
 * ログインは永続的なやつに変更
 */
import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import {
  initializeFirestore,
  enableIndexedDbPersistence,
  CACHE_SIZE_UNLIMITED,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, setPersistence, indexedDBLocalPersistence, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import { setToIndexedDb } from './functions/indexedDb';
import { saveToSessionStorage, loadFromSessionStorage } from './functions/storageIO';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

// Firebaseアプリの初期化
firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);

// firebase storage
const storage = getStorage();

// Firebase Authentication：永続性をONにする
export const auth = getAuth(firebaseApp);
setPersistence(auth, indexedDBLocalPersistence);

// Firebase Firestore：永続性をONにする&容量無制限
export const db = initializeFirestore(firebaseApp, { cacheSizeBytes: CACHE_SIZE_UNLIMITED });
enableIndexedDbPersistence(db).catch((e) => console.log('Error: enableIndexedDbPersistence', e));

// firestoreからuidに対応するデータを取得
export const readFromFirestore = async (uid) => {
  const docRef = doc(db, 'users', uid);
  return getDoc(docRef)
    .then((docSnap) => {
      console.log('readFromFirestore', docSnap.data(), uid);
      const ret = {
        uid,
        email: docSnap.data().email,
        bound: docSnap.data().bound,
        role: docSnap.data().role,
        fads: docSnap.data().fads,
        download: docSnap.data().download,
        log: docSnap.data().log,
        forceOffline: docSnap.data().force_offline || false,
      };
      return ret;
    })
    .catch((e) => {
      throw e;
    });
};
// firestoreへuidに対応するデータを書き込む: 本番時は使いません
export const writeToFirestore = async (uid, username, email) => {
  const docRef = doc(db, 'users', uid);
  const data = {
    uid,
    username,
    email,
    bound: 'all',
    role: 'customer',
  };
  return setDoc(docRef, data);
};

export const writeLogToFirestore = (uid, log, date) => {
  const docRef = doc(db, 'users', uid, 'log', date);
  updateDoc(docRef, log).catch((e) => {
    if (e.code === 'not-found') {
      setDoc(docRef, log);
    }
  });
};

export const saveLog = (uid, tiletype, displayDate) => {
  const nowDate = new Date();
  const todayDateStr = nowDate.toISOString().split('T')[0];
  const timeStr = nowDate.toISOString().split('T')[1].split('.')[0];
  const datetimeStr = nowDate.toUTCString();

  const saveWithLocation = (position) => {
    const location = { lat: position.coords.latitude, lng: position.coords.longitude };
    const log = { [timeStr]: { date: datetimeStr, displayDate, location, tiletype } };
    writeLogToFirestore(uid, log, todayDateStr);
  };
  const saveWithoutLocation = () => {
    const log = { [timeStr]: { date: datetimeStr, displayDate, tiletype } };
    writeLogToFirestore(uid, log, todayDateStr);
  };

  navigator.geolocation.getCurrentPosition(saveWithLocation, saveWithoutLocation);
};

// forceOfflineをfirestoreに保存
export const saveForceOffline = (uid, forceOffline) => {
  const docRef = doc(db, 'users', uid);
  updateDoc(docRef, { force_offline: forceOffline });
};

// workboxのキャッシュ戦略を変更
export function changeCacheMode(mode) {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({ type: 'MODE', payload: mode });
  }
}

export const readRoleData = async (role) => {
  const docRef = doc(db, 'roles', role);
  return getDoc(docRef)
    .then((docSnap) => {
      const ret = {
        role,
        tileTypes: docSnap.data().tileTypes,
        depths: docSnap.data().depths,
      };
      return ret;
    })
    .catch((e) => {
      throw e;
    });
};

// ログイン＆reload時の処理：
export const onLogin = async (user) => {
  // firestoreからデータを取得
  if (!user || !user.uid) {
    throw new Error('onLogin: user is not defined');
  }
  return readFromFirestore(user.uid)
    .then((data) => {
      saveToSessionStorage('bound', data.bound);
      saveToSessionStorage('uid', data.uid);
      if (data.download) {
        saveToSessionStorage('download', data.download);
      } else {
        saveToSessionStorage('download', []);
      }
      if (data.fads) {
        saveToSessionStorage('fads', data.fads);
      } else {
        saveToSessionStorage('fads', []);
      }
      const mode = data.forceOffline ? 'CACHEONLY' : 'CACHEFIRST';
      changeCacheMode(mode);
      setToIndexedDb('user-settings', 'service-worker', 'forceOffline', data.forceOffline);
      return saveToSessionStorage('isLogin', true);
    })
    .catch((e) => {
      saveToSessionStorage('isLogin', false);
      throw e;
    });
};

// ログイン状態の判定
export const isLogin = () => loadFromSessionStorage('isLogin', false);

// 一括ダウンロードのパターンを取得
export const getDownloadPattern = () => loadFromSessionStorage('download', []);

// FADの領域名の取得
export const getFADRegion = () => loadFromSessionStorage('fads', []);

// FADのURLの取得
export async function getFADUrl(date, region) {
  const dateStr =
    date.year.toString() + (date.month + 1).toString().padStart(2, '0') + date.day.toString().padStart(2, '0');

  // URL:gs://fnavi-pro-v3-stg.appspot.com/fads/{region}/{date}.geojson
  const blobname = `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/fads/${region}/${dateStr}0000.geojson`;
  console.log('blobname', blobname);

  return getDownloadURL(ref(storage, blobname));
}

// 描画範囲の取得
export const getBound = () => {
  const bound = loadFromSessionStorage('bound', 'all');
  if (bound === 'all') return null;
  return bound;
};

// 認証状態の変更を監視。
onAuthStateChanged(auth, async (user) => {
  if (user) {
    onLogin(user).catch((e) => {
      alert('ログインに失敗しました。');
      // eslint-disable-next-line no-console
      console.log('error', e);
    });
  } else {
    // ログアウト時の処理：セッションストレージに isLogin = falseを保存
    saveToSessionStorage('isLogin', false);
  }

  return true;
});

export default firebaseApp;
