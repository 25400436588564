/*
可視タイル・深度の管理

※現状可視タイル・深度は一つだけ。水温＋流速みたいな表記をする場合は要変更
*/

import { createSlice } from '@reduxjs/toolkit';
import { tileSetting, isolineSetting } from '../configs/datasource';
import { saveToSessionStorage, loadFromSessionStorage } from '../functions/storageIO';

function isRaster(tileType) {
  return tileSetting()[tileType].isRaster;
}
function isVector(tileType) {
  return !tileSetting()[tileType].isRaster;
}
function hasIso(tileType) {
  return tileSetting()[tileType].hasIso;
}

function validateDepth(tileType, depth) {
  const avail = tileSetting()[tileType].availableDepths;
  if (avail.includes(Number(depth))) {
    return Number(depth);
  }
  return avail[0];
}

export const visibleTileSlice = createSlice({
  name: 'visibleTile',
  initialState: loadFromSessionStorage('visibleTile', {
    tileType: 'himawari',
    isRasterVisible: true,
    isContourVisible: true,
    isVectorVisible: false,
    isBathymetryVisible: false,
    depth: { himawari: 0, gan: 0, tsst: 0, msst: 0, tcur: 0, mcur: 0, sla: 0, chl: 0 },
    hasIsoLine: true,
    isContourButtonVisible: true,
    isVectorOverlayVisible: false,
    isEezBoundaryVisible: false,
  }),
  reducers: {
    /* eslint-disable prefer-destructuring, no-param-reassign */
    setTileType: (state, action) => {
      state.tileType = action.payload[0];
      state.isRasterVisible = isRaster(action.payload[0]);
      state.isVectorVisible = isVector(action.payload[0]);
      state.hasIsoLine = hasIso(action.payload[0]);
      state.depth[action.payload[0]] = validateDepth(action.payload[0], action.payload[1]);
      saveToSessionStorage('visibleTile', state);
    },
    setDepth: (state, action) => {
      state.depth[state.tileType] = validateDepth(state.tileType, action.payload);
      saveToSessionStorage('visibleTile', state);
    },
    toggleContourVisibility: (state) => {
      state.isContourVisible = !state.isContourVisible;
      saveToSessionStorage('visibleTile', state);
    },
    toggleBathymetryVisibility: (state) => {
      state.isBathymetryVisible = !state.isBathymetryVisible;
      saveToSessionStorage('visibleTile', state);
    },
    setContourButtonVisibility: (state, action) => {
      const flag = action.payload >= isolineSetting.minZoom1;
      if (flag !== state.isContourButtonVisible) {
        state.isContourButtonVisible = flag;
        saveToSessionStorage('visibleTile', state);
      }
    },
    toggleOverlayVisibility: (state) => {
      state.isVectorOverlayVisible = !state.isVectorOverlayVisible;
      saveToSessionStorage('visibleTile', state);
    },
    toggleEezVisibility: (state) => {
      state.isEezBoundaryVisible = !state.isEezBoundaryVisible;
      saveToSessionStorage('visibleTile', state);
    },
    /* eslint-enable prefer-destructuring, no-param-reassign */
  },
});

export const {
  setTileType,
  setDepth,
  toggleContourVisibility,
  toggleBathymetryVisibility,
  setContourButtonVisibility,
  toggleOverlayVisibility,
  toggleEezVisibility,
} = visibleTileSlice.actions;

export default visibleTileSlice.reducer;
