import { createSlice } from '@reduxjs/toolkit';

export const sliderTooltipSlice = createSlice({
  name: 'sliderTooltip',
  initialState: {
    isShowSliderTooltip: true,
  },
  reducers: {
    /* eslint-disable prefer-destructuring, no-param-reassign */
    toggleShowSliderTooltip: (state, action) => {
      state.isShowSliderTooltip = action.payload;
    },
    /* eslint-enable prefer-destructuring, no-param-reassign */
  },
});

export const { toggleShowSliderTooltip } = sliderTooltipSlice.actions;

export default sliderTooltipSlice.reducer;
