import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import i18n from './i18n/configs';

// サービスワーカー用
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// ステート管理用
import { store } from './store/index';

// 開発時以外はconsole.logを使わない
// eslint-disable-next-line
process.env.NODE_ENV !== 'development' && (console.log = () => {});

// とりあえず<App/>をレンダリング＆ステート読み込み
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

/*
// ここ、単にストレージの残量を調べるコードです。今は不要。
if ('storage' in navigator && 'estimate' in navigator.storage) {
  navigator.storage.estimate().then((estimate) => {
    console.log(`Using ${estimate.usage} out of ${estimate.quota} bytes.`);
  });
} */

// 今はまだ使わない…かな。
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

/* 
   サービスワーカーの設定項目
    onUpdate : アプリ更新が存在してる場合に呼ばれる処理→メッセージを表示してからSKIP_WAITINGを呼ぶ
  */
const swConfig = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      sessionStorage.removeItem('valueRange');
      window.alert(i18n.t('serviceworker.appUpdate'));
      window.location.reload();
    }
  },
};

// サービスワーカーを登録する
serviceWorkerRegistration.register(swConfig);
