export function saveToSessionStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function loadFromSessionStorage(key, defaultValue) {
  if (sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  sessionStorage.setItem(key, JSON.stringify(defaultValue));
  return defaultValue;
}

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function loadFromLocalStorage(key, defaultValue) {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
  localStorage.setItem(key, JSON.stringify(defaultValue));
  return defaultValue;
}

export default function clearSessionStorage() {
  sessionStorage.clear();
}
