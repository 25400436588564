// https://qiita.com/Syoitu/items/98343af1bd69e7c38283
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationJA from './ja.json';
import translationID from './id.json';
import translationZHTW from './zh_tw.json';

const resources = {
  ja: {
    translation: translationJA,
  },
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
  zh: {
    translation: translationZHTW,
  },
};

const options = {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ['en', 'ja', 'id', 'zh'],
    resources,
    detection: options,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
