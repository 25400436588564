/*
色付け範囲のためのステート
- ここではvsmallとvlargeを決めるだけ
- 設定はrangeTypesに記述

※現状、同時に変更可能なのは１つだけ
*/
import { createSlice } from '@reduxjs/toolkit';
import { tileSetting, rangeSetting } from '../configs/datasource';
import { saveToSessionStorage, loadFromSessionStorage } from '../functions/storageIO';

export const valueRangeSlice = createSlice({
  name: 'valueRange',
  initialState: {
    all: loadFromSessionStorage('valueRange', { ...rangeSetting, type: 'seaTemperature' }),
  },
  reducers: {
    setRange: (state, action) => {
      /* eslint-disable prefer-destructuring, no-param-reassign */
      state.all = {
        ...state.all,
        [state.all.type]: {
          ...state.all[state.all.type],
          vsmall: action.payload[0],
          vlarge: action.payload[1],
        },
      };
      /* eslint-enable prefer-destructuring, no-param-reassign */
      saveToSessionStorage('valueRange', state.all);
    },
    // 範囲変更する対象を変更。rangeにあるtype限定。
    setTileTypeRangeSlider: (state, action) => {
      /* eslint-disable no-param-reassign */
      state.all = { ...state.all, type: tileSetting()[action.payload].rangeTypeName };
      /* eslint-enable no-param-reassign */
      saveToSessionStorage('valueRange', state.all);
    },
  },
});

export const { setRange, setTileTypeRangeSlider } = valueRangeSlice.actions;

export default valueRangeSlice.reducer;
