/*
日付操作用のstate
*/
import { createSlice } from '@reduxjs/toolkit';
import { saveToSessionStorage, loadFromSessionStorage } from '../functions/storageIO';

// 「現在」ボタンを押した場合＆起動時に表示される時刻。現在時刻より1時間前
function defaultDate() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() - 1, 0, 0, 0);
}

// 日付のシリアライズとデシリアライズ
export function dateSerialize(date) {
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth(),
    day: date.getUTCDate(),
    hour: date.getUTCHours(),
  };
}
export function dateDeserialize(dateserial) {
  return new Date(Date.UTC(dateserial.year, dateserial.month, dateserial.day, dateserial.hour));
}

// 日付state
export const datetimeSlice = createSlice({
  name: 'displayDatetime',
  initialState: {
    datetimeUTC: loadFromSessionStorage('displayDatetime', dateSerialize(defaultDate())),
  },
  reducers: {
    /* eslint-disable no-param-reassign */
    set: (state, action) => {
      state.datetimeUTC = action.payload;
      saveToSessionStorage('displayDatetime', state.datetimeUTC);
    },
    reset: (state) => {
      state.datetimeUTC = dateSerialize(defaultDate());
      saveToSessionStorage('displayDatetime', state.datetimeUTC);
    },
    increaseHour: (state, action) => {
      const datetime = dateDeserialize(state.datetimeUTC);
      datetime.setUTCHours(datetime.getUTCHours() + action.payload);
      state.datetimeUTC = dateSerialize(datetime);
      saveToSessionStorage('displayDatetime', state.datetimeUTC);
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { set, reset, increaseHour } = datetimeSlice.actions;

export default datetimeSlice.reducer;
