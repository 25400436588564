/*
 * 地図情報の管理
 */

import { createSlice } from '@reduxjs/toolkit';
import { saveToSessionStorage, loadFromSessionStorage } from '../functions/storageIO';

export const mapinfoSlice = createSlice({
  name: 'mapinfo',
  initialState: loadFromSessionStorage('mapinfo', {
    isShowTooltip: false,
    isMeasureMode: false,
  }),
  reducers: {
    /* eslint-disable prefer-destructuring, no-param-reassign */
    toggleShowTooltip: (state, action) => {
      state.isShowTooltip = action.payload;
      saveToSessionStorage('mapinfo', state);
    },
    toggleMeasureMode: (state, action) => {
      state.isMeasureMode = action.payload;
      saveToSessionStorage('mapinfo', state);
    },
    /* eslint-enable prefer-destructuring, no-param-reassign */
  },
});

export const { toggleMeasureMode, toggleShowTooltip } = mapinfoSlice.actions;

export default mapinfoSlice.reducer;
