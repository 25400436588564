// import { createContext, useState, useContext, useEffect } from 'react';
import React from 'react';

import { auth, readFromFirestore, readRoleData } from '../firebase';

const AuthContext = React.createContext();

export function useAuthContext() {
  return React.useContext(AuthContext);
}

// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const [userData, setUserData] = React.useState({});
  const [roleData, setRoleData] = React.useState({});

  const value = React.useMemo(
    () => ({
      userData,
      setUserData,
      roleData,
    }),
    [userData, roleData]
  );

  React.useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((user) => {
      readFromFirestore(user.uid)
        .then((data) => {
          setUserData(data);
          return readRoleData(data.role);
        })
        .then((role) => {
          setRoleData(role);
        });
    });
    return () => {
      unsubscribed();
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
