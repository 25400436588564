/*
 * 地図情報
 */

import { createSlice } from '@reduxjs/toolkit';

export const mapMarkerSlice = createSlice({
  name: 'mapMarker',
  initialState: {
    srcMarkerLocation: null,
    dstMarkerLocation: null,
    popupContent: null,
    detailedPopupContent: null,
  },
  reducers: {
    /* eslint-disable prefer-destructuring, no-param-reassign */
    setSrcMarkerLocation: (state, action) => {
      if (action.payload && !state.srcMarkerLocation) {
        state.srcMarkerLocation = action.payload;
      }
    },
    moveSrcMarkerLocation: (state, action) => {
      if (action.payload && state.srcMarkerLocation) {
        state.srcMarkerLocation = action.payload;
      }
    },
    setDstMarkerLocation: (state, action) => {
      if (action.payload) {
        state.dstMarkerLocation = action.payload;
      }
    },
    clearMarkerLocation: (state) => {
      state.srcMarkerLocation = null;
      state.dstMarkerLocation = null;
    },
    setPopupContent: (state, action) => {
      state.popupContent = action.payload;
    },
    clearPopupContent: (state) => {
      state.popupContent = null;
    },
    setDetailedPopupContent: (state, action) => {
      state.detailedPopupContent = action.payload;
    },
    clearDetailedPopupContent: (state) => {
      state.detailedPopupContent = null;
    },
    /* eslint-enable prefer-destructuring, no-param-reassign */
  },
});

export const {
  setSrcMarkerLocation,
  moveSrcMarkerLocation,
  setDstMarkerLocation,
  clearMarkerLocation,
  setPopupContent,
  clearPopupContent,
  setDetailedPopupContent,
  clearDetailedPopupContent,
} = mapMarkerSlice.actions;

export default mapMarkerSlice.reducer;
