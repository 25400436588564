let dbInstance;

function getDB(dbName, storeName) {
  if (dbInstance) return dbInstance;

  dbInstance = new Promise((resolve, reject) => {
    const openreq = indexedDB.open(dbName, 1);

    openreq.onerror = () => {
      reject(openreq.error);
    };

    openreq.onupgradeneeded = () => {
      // First time setup: create an empty object store
      openreq.result.createObjectStore(storeName);
    };

    openreq.onsuccess = () => {
      resolve(openreq.result);
    };
  });

  return dbInstance;
}

async function withStore(dbName, storeName, type, callback) {
  const db = await getDB(dbName, storeName);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, type);
    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
    callback(transaction.objectStore(storeName));
  });
}

export async function getFromIndexedDb(dbName, storeName, key) {
  let request;
  await withStore(dbName, storeName, 'readonly', async (store) => {
    request = store.get(key);
  });
  return request.result;
}
export function setToIndexedDb(dbName, storeName, key, value) {
  return withStore(dbName, storeName, 'readwrite', (store) => {
    store.put(value, key);
  });
}
export function deleteIndexedDb(dbName, storeName, key) {
  return withStore(dbName, storeName, 'readwrite', (store) => {
    store.delete(key);
  });
}
