import { configureStore } from '@reduxjs/toolkit';
import datetimeReducer from './datetimeSlice';
import visibleTileReducer from './visibleTileSlice';
import valueRangeReducer from './valueRangeSlice';
import userSettingsReducer from './userSettingsSlice';
import mapinfoReducer from './mapinfoSlice';
import mapMarkerReducer from './mapMarkerSlice';
import sliderTooltipReducer from './sliderTooltipSlice';
import downloadDateReducer from './downloadDateSlice';

export const store = configureStore({
  reducer: {
    displayDatetime: datetimeReducer,
    visibleTileInfo: visibleTileReducer,
    valueRange: valueRangeReducer,
    userSettings: userSettingsReducer,
    mapInfo: mapinfoReducer,
    mapMarker: mapMarkerReducer,
    sliderTooltip: sliderTooltipReducer,
    lastDownloadDate: downloadDateReducer,
  },
});

// 特に意味ないです。
export default function version() {
  return null;
}
