import { format, utcToZonedTime } from 'date-fns-tz';

/*
データソースの整理

# Raster + Contour
1. ひまわり水温
  - /himawarirgb/${datetime}/{z}/{x}/{y}.png
  - /himawari-iso/${datetime}/{z}/{x}/{y}.pbf
2. 雲無し水温
  - /ganrgb/${datetime}/{z}/{x}/{y}.png
  - /gan-iso/${datetime}/{z}/{x}/{y}.pbf
3. 沿岸水温
  - /tsstrgb/${depth}/${datetime}/{z}/{x}/{y}.png
  - /tsst-iso/${depth}/${datetime}/{z}/{x}/{y}.pbf
4. 広域水温
  - /msstrgb/${depth}/${datetime}/{z}/{x}/{y}.png
  - /msst-iso/${depth}/${datetime}/{z}/{x}/{y}.pbf
5. クロロフィル
  - /chlrgb/${datetime}/{z}/{x}/{y}.png
  - /chl-iso/${datetime}/{z}/{x}/{y}.pbf
6. 海面高度
  - /slargb/${datetime}/{z}/{x}/{y}.png
  - /sla-iso/${datetime}/{z}/{x}/{y}.pbf
7. 沿岸潮目
  - /tseorgb/${depth}/${datetime}/{z}/{x}/{y}.png
  - /tseo-iso/${depth}/${datetime}/{z}/{x}/{y}.pbf
8. 広域潮目
  - /mseorgb/${depth}/${datetime}/{z}/{x}/{y}.png
  - /mseo-iso/${depth}/${datetime}/{z}/{x}/{y}.pbf

# 2ch Raster (Contourは要らない？)
1. 沿岸流速
  - /tcurrgb/${depth}/${datetime}/{z}/{x}/{y}.png
  - /tcurrgb_dir/${depth}/${datetime}/{z}/{x}/{y}.png
2. 広域流速
  - /mcurrgb/${depth}/${datetime}/{z}/{x}/{y}.png
  - /mcurrgb_dir/${depth}/${datetime}/{z}/{x}/{y}.png
*/

const BASE_URL = 'https://oceaneyes.storage.googleapis.com/v2';

export const tileSetting = (datetimeStr = null, depth = 0) => ({
  himawari: {
    desc: 'layer.himawari',
    isRaster: true,
    hasIso: true,
    rgb: `${BASE_URL}/himawarirgb/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/himawari-iso/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0],
    rangeTypeName: 'seaTemperature',
    isWideArea: true,
  },
  gan: {
    desc: 'layer.gan',
    isRaster: true,
    hasIso: true,
    rgb: `${BASE_URL}/ganrgb/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/gan-iso/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0],
    rangeTypeName: 'seaTemperature',
    isWideArea: true,
  },
  tsst: {
    desc: 'layer.tsst',
    isRaster: true,
    hasIso: true,
    rgb: `${BASE_URL}/tsstrgb/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/tsst-iso/${depth}/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0, 50, 100, 150, 200],
    rangeTypeName: 'seaTemperature',
    isWideArea: false,
  },
  msst: {
    desc: 'layer.msst',
    isRaster: true,
    hasIso: true,
    rgb: `${BASE_URL}/msstrgb/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/msst-iso/${depth}/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0, 10, 30, 50, 100, 150, 200],
    rangeTypeName: 'seaTemperature',
    isWideArea: true,
  },
  chl: {
    desc: 'layer.chl',
    isRaster: true,
    hasIso: true,
    rgb: `${BASE_URL}/chlrgb/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/chl-iso/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0],
    rangeTypeName: 'chlorophyll',
    isWideArea: true,
  },
  sla: {
    desc: 'layer.sla',
    isRaster: true,
    hasIso: true,
    rgb: `${BASE_URL}/mslargb/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/msla-iso/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0],
    rangeTypeName: 'seaLevel',
    isWideArea: true,
  },
  tsea: {
    desc: 'layer.tsea',
    isRaster: true,
    hasIso: false,
    rgb: `${BASE_URL}/tseargb/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/tsea-iso/${depth}/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0, 50, 100, 150, 200],
    rangeTypeName: 'seaome',
    isWideArea: false,
  },
  msea: {
    desc: 'layer.msea',
    isRaster: true,
    hasIso: false,
    rgb: `${BASE_URL}/mseargb/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    iso: `${BASE_URL}/msea-iso/${depth}/${datetimeStr}/{z}/{x}/{y}.pbf`,
    availableDepths: [0, 10, 30, 50, 100, 150, 200],
    rangeTypeName: 'seaome',
    isWideArea: true,
  },
  tcur: {
    desc: 'layer.tcur',
    isRaster: false,
    hasIso: false,
    rgb: `${BASE_URL}/tcurrgb/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    dir: `${BASE_URL}/tcurrgb_dir/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    availableDepths: [0, 50, 100, 150, 200],
    rangeTypeName: 'current',
    isWideArea: false,
  },
  mcur: {
    desc: 'layer.mcur',
    isRaster: false,
    hasIso: false,
    rgb: `${BASE_URL}/mcurrgb/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    dir: `${BASE_URL}/mcurrgb_dir/${depth}/${datetimeStr}/{z}/{x}/{y}.png`,
    availableDepths: [0, 10, 30, 50, 100, 150, 200],
    rangeTypeName: 'current',
    isWideArea: true,
  },
});

// 地形タイル
export const baseMapTileURL = 'https://oceaneyes.storage.googleapis.com/v2/land/{z}/{x}/{y}.png';

// 海底地形タイル
export const bathymetryURL = `${BASE_URL}/bathymetry/{z}/{x}/{y}.pbf`;

// 200海里線タイル
export const eezBoundaryURL = `${BASE_URL}/eez/{z}/{x}/{y}.pbf`;

// 表示タイル選択メニューの表示順
export const tileMenuOrder = ['himawari', 'gan', 'tsst', 'msst', 'tcur', 'mcur', 'sla', 'chl', 'msea'];

// 等値線の設定
export const isolineSetting = {
  minZoom1: 5,
  minZoom02: 9,
};

export const rangeSetting = {
  seaTemperature: {
    vmin: 0,
    vmax: 35,
    vstep: 1,
    vmargin: 2,
    vsmall: 0,
    vlarge: 35,
    unit: '℃',
    colormap: 'jet',
    nDigit: 1,
    isLog: false,
  },
  chlorophyll: {
    vmin: 0.01,
    vmax: 100,
    vstep: 1,
    vmargin: 1,
    vsmall: 0.01,
    vlarge: 100,
    unit: 'mg/m³',
    colormap: 'chlorophyll',
    nDigit: 3,
    isLog: true,
  },
  seaLevel: {
    vmin: -1.5,
    vmax: 1.5,
    vstep: 0.1,
    vmargin: 2,
    vsmall: -1.5,
    vlarge: 1.5,
    unit: 'm',
    colormap: 'bluered',
    nDigit: 1,
    isLog: false,
  },
  seaome: {
    vmin: -3,
    vmax: 30,
    vstep: 1,
    vmargin: 2,
    vsmall: 0,
    vlarge: 30,
    unit: '',
    colormap: 'viridis',
    nDigit: 1,
    isLog: false,
  },
  current: {
    vmin: 0,
    vmax: 10,
    vstep: 0.1,
    vmargin: 1,
    vsmall: 0,
    vlarge: 3,
    unit: 'kt',
    colormap: 'jet',
    nDigit: 2,
    isLog: false,
  },
};

/*
  現在時刻をUTCで表記したしたとき
  表示時刻は、現在時刻-1時間前になるように調整する。

  ひまわりと雲除去：表示時刻の分以降を0に切り捨て
  沿岸予測：毎時0,6,12,18のデータが存在。
    21:00-2:59 -> 0
    3:00-8:59 -> 6
    9:00-14:59 -> 12
    15:00-20:59 -> 18
  広域予測、クロロフィル、海面高度：0時のデータが存在
    12:00-11:59 -> 0
*/
function truncHour(date) {
  const d = new Date(date);
  d.setUTCMinutes(0);
  d.setUTCSeconds(0);
  d.setUTCMilliseconds(0);
  return d;
}

function round6Hours(date) {
  const d = truncHour(date);
  const UTChour = d.getUTCHours();
  if (UTChour < 3) {
    d.setUTCHours(0);
  } else if (UTChour < 9) {
    d.setUTCHours(6);
  } else if (UTChour < 15) {
    d.setUTCHours(12);
  } else if (UTChour < 21) {
    d.setUTCHours(18);
  } else {
    d.setUTCHours(0);
    d.setUTCDate(d.getUTCDate() + 1);
  }
  return d;
}

function roundDay(date) {
  const d = truncHour(date);
  const UTChour = d.getUTCHours();
  if (UTChour >= 12) {
    d.setUTCDate(d.getUTCDate() + 1);
  }
  d.setUTCHours(0);
  return d;
}

export function date2date(date, dataType) {
  switch (dataType) {
    case 'himawari':
    case 'gan':
      return truncHour(date);
    case 'tsst':
    case 'tcur':
    case 'tsea':
      return round6Hours(date);
    case 'chl':
    case 'sla':
    case 'msst':
    case 'mcur':
    case 'msea':
      return roundDay(date);
    default:
      return null;
  }
}

export function date2str(date) {
  return format(utcToZonedTime(date, 'UTC'), 'yyyyMMddHH00');
}

export const getInterval = (dataType) => {
  switch (dataType) {
    case 'himawari':
    case 'gan':
      return 1;
    case 'tsst':
    case 'tcur':
    case 'tsea':
      return 6;
    case 'chl':
    case 'sla':
    case 'msst':
    case 'mcur':
    case 'msea':
      return 24;
    default:
      return null;
  }
};

export const tileNames = Object.keys(tileSetting(null, null));

export default function getTileInfo(date, dtype, depth) {
  const dataDate = date2date(date, dtype);
  const datetimeStr = date2str(dataDate);
  const tile = tileSetting(datetimeStr, depth);
  return tile[dtype];
}
