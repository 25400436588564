/*
 * ユーザ設定情報の管理
 */

import { createSlice } from '@reduxjs/toolkit';
import { saveToLocalStorage, loadFromLocalStorage } from '../functions/storageIO';

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: loadFromLocalStorage('userSettings', {
    DMS: 'DMS',
  }),
  reducers: {
    /* eslint-disable prefer-destructuring, no-param-reassign */
    setDMS: (state, action) => {
      state.DMS = action.payload;
      saveToLocalStorage('userSettings', state);
    },
    /* eslint-enable prefer-destructuring, no-param-reassign */
  },
});

export const { setDMS } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
